export enum ELifeScienceStatus {
  ARCHIVED = 'Archived',
  COMPLETED = 'Completed',
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  ON_GOING = 'On-going',
  ON_HOLD_BY_QIMA = 'On-hold by QIMA',
  PENDING_FEEDBACK = 'Pending Feedback',
  PENDING_PAYMENT = 'Pending Payment',
  PENDING_PO = 'Pending PO',
  PENDING_TEST_ITEM = 'Pending Test Item',
  RESULTS_READY = 'Intermediary Results',
  SUSPEND_BY_CLIENT = 'Suspended by Client',
}
