import { IAccountMenuItem } from '@aca-new/app/pages/account/shared/models/interfaces/account-menu-item.interface';
import { HeaderService } from '@aca-new/app/pages/main/shared/components/header/shared/service/header.service';
import { FreshChatService } from '@aca-new/app/pages/main/shared/services/fresh-chat.service';
import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { EBrokerType } from '@aca-new/app/shared/models/enums/broker-type.enum';
import { EPostMessageType } from '@aca-new/app/shared/models/enums/post-message-type.enum';
import { QimaIconService } from '@aca-new/app/shared/services/exported-services/qima-icon/qima-icon.service';
import { QimaShadowService } from '@aca-new/app/shared/services/exported-services/qima-shadow/qima-shadow.service';
import { QimaTooltipService } from '@aca-new/app/shared/services/exported-services/qima-tooltip/qima-tooltip.service';
import { AcaMessageService } from '@aca-new/app/shared/services/post-message-services/aca-message/aca-message.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QimaOptionalType } from '@qima/ngx-qima';

@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMenuComponent implements OnInit {
  public currentUser: IUser = this._appAuthenticationService.getCurrentUser();
  public isBroker: boolean = this.currentUser?.company?.brokerType === EBrokerType.BROKER;
  public parentCompanyName: QimaOptionalType<string> = this.currentUser.company?.parentCompanyName;

  public profileMenuItems: IAccountMenuItem[] = [
    {
      label: 'APP_HEADER.ACCOUNT.MY_ACCOUNT',
      path: '/account/access-credentials',
    },
    {
      label: 'APP_HEADER.ACCOUNT.PREFERENCES',
      path: '/account/booking-preferences',
    },
    {
      label: 'APP_HEADER.ACCOUNT.DOCUMENTS',
      path: '/account/my-documents',
    },
  ];

  public constructor(
    private readonly _headerService: HeaderService,
    private readonly _acaMessageService: AcaMessageService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    private readonly _router: Router,
    private readonly _freshChatService: FreshChatService,
    private readonly _appUserSettingService: AppUserSettingService,
    public readonly shadowService: QimaShadowService,
    public readonly qimaTooltip: QimaTooltipService,
    public readonly qimaIcon: QimaIconService
  ) {}

  public ngOnInit(): void {
    this._initData();
  }

  public onAccountMenuItemClick(item: IAccountMenuItem): void {
    this._closeMenu();

    if (this._acaMessageService.isAcaFrameAvailable) {
      this._acaMessageService.informAcaPathChange(item.path);
    } else {
      void this._router.navigateByUrl(item.path);
    }
  }

  public logout(): void {
    this._freshChatService.clearFreshChat();
    this._appAuthenticationService.logout();
    this._acaMessageService.postMessageToAca({
      type: EPostMessageType.LOGOUT,
    });
  }

  private _closeMenu(): void {
    this._headerService.updateIsProfileMenuVisible(false);
  }

  private _initData(): void {
    if (this._appUserSettingService.getIsQimaProduce()) {
      this.profileMenuItems = [this.profileMenuItems[0]];
    } else if (this._appUserSettingService.getIsLifeScience()) {
      this.profileMenuItems = [];
    }
  }
}
