/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum EOrderStatus {
  DRAFT = 5,
  DRAFT_WAITING_FOR_AI_ANSWER = 5,
  DRAFT_WAITING_FOR_BOOKING = 8,
  UNCOMPLETED = 10,
  WAITING_FOR_QUOTATION = 15,
  WAITING_FOR_APPROBATION = 17,
  WAITING_FOR_PAYMENT = 18,
  WAITING_FOR_VALIDATION = 20,
  WAITING_FOR_MORE_INFO = 21,
  WAITING_FOR_SAMPLE = 22,
  WAITING_FOR_ADDITIONAL_SAMPLE = 23,
  REPORTING = 24,
  WAITING_FOR_FEEDBACK = 25,
  WAITING_FOR_ALLOCATION = 30,
  WAITING_FOR_FINAL_ORDER_PRICE = 30,
  WAITING_FOR_CLIENT_APPROVAL = 35,
  WAITING_FOR_INSPECTION = 40,
  WAITING_FOR_AUDIT = 40,
  WAITING_FOR_TESTING = 40,
  ONGOING_AUDIT = 41,
  WAITING_FOR_REPORT_SUBMISSION = 42,
  WAITING_FOR_REPORT_CORRECTION = 51,
  WAITING_FOR_REPORT_VALIDATION = 50,
  WAITING_FOR_AUDITOR_EVALUATION = 55,
  WAITING_FOR_ISSUE_CERTIFICATION = 56,
  FINISHED = 60,
  CANCELLED = 70,
  SERVICE_CUT = 80,
}
