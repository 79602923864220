import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { ISystemEvent } from '@aca-new/app/shared/models/interfaces/system-event.interface';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// eslint-disable-next-line @typescript-eslint/naming-convention
import UAParser from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class AppSystemEventService {
  public constructor(
    private readonly _httpService: HttpService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public sendSystemEvent$(data: ISystemEvent): Observable<void> {
    const userData: IUser = this._appAuthenticationService.getCurrentUser();
    const uaParser = new UAParser();

    return this._httpService.httpClient
      .post<void>(
        `${SERVER_URL}/v1.0/system-event`,
        {
          appName: 'myQima',
          os: JSON.stringify(uaParser.getOS() || ''),
          userId: userData.id,
          userName: userData.login,
          deviceName: JSON.stringify(uaParser.getDevice() || ''),
          ...data,
          sourceId: data.sourceId || userData.id,
          dataJson: {
            ...data.dataJson,
            time: new Date().toISOString(),
            userAgentDetails: {
              ua: uaParser.getUA(),
              browser: uaParser.getBrowser(),
              engine: uaParser.getEngine(),
              cpu: uaParser.getCPU(),
            },
          },
        },
        { observe: 'body' }
      )
      .pipe(map((response: void): void => response));
  }
}
