<div
  [qimaShadowLevel]="shadowService.shadowLevel.TWO"
  class="account-menu"
  qimaShadow
>
  <div
    *ngIf="parentCompanyName"
    class="account-menu__master qima-pb-s"
  >
    <div class="account-menu__master__sub">{{ 'APP_HEADER.ACCOUNT.SUB_ACCOUNT_OF' | translate }}</div>
    <div
      [qimaAutoTooltipHasArrow]="true"
      [qimaAutoTooltipPosition]="qimaTooltip.position.BOTTOM"
      [qimaAutoTooltipStrategy]="qimaTooltip.strategy.ON_HOVER"
      [qimaAutoTooltipStyle]="qimaTooltip.style.DARK"
      [qimaAutoTooltipText]="parentCompanyName"
      class="account-menu__master__name"
      qimaAutoTooltip
    >
      {{ parentCompanyName }}
    </div>
  </div>

  <div class="account-menu__user-info">
    <div class="account-menu__user-info__icon">
      <qima-icon [qimaIconName]="qimaIcon.iconName.USER"></qima-icon>
    </div>

    <div class="qima-ml-s">
      <div class="account-menu__user-info__name">{{ currentUser.firstName }} {{ currentUser.lastName }}</div>
      <div class="account-menu__user-info__name-label">{{ currentUser.login }}</div>
    </div>
  </div>
  <div
    *ngIf="profileMenuItems.length"
    class="account-menu__items"
  >
    <div
      *ngFor="let item of profileMenuItems; let index = index"
      appNgForTrackByUuid
    >
      <div
        (click)="onAccountMenuItemClick(item)"
        *ngIf="!(isBroker && index === 1)"
        class="account-menu__items__item"
      >
        <span class="account-menu__items__item__label">{{ item.label | translate }}</span>
      </div>
    </div>
  </div>
  <div
    (click)="logout()"
    (keydown.enter)="logout()"
    class="account-menu__logout"
  >
    <qima-icon
      [qimaIconName]="qimaIcon.iconName.LOGOUT"
      class="account-menu__logout__avatar"
    ></qima-icon>
    <span class="account-menu__logout__label">{{ 'APP_HEADER.ACCOUNT.LOGOUT' | translate }}</span>
  </div>
</div>
