import { EBusinessUnit } from '@aca-new/app/shared/models/enums/business-unit.enum';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

/**
 * @description
 * Life science BUs have access to life science module
 * @returns {boolean} - can access or not
 */
export default function lifeScienceGuard(): boolean {
  const appUserSettingService = inject(AppUserSettingService);
  const businessUnit = appUserSettingService.getBusinessUnit();
  const lifeScienceBusinessUnits = [EBusinessUnit.MONASTERIUM, EBusinessUnit.NEWTONE, EBusinessUnit.BIOA];

  if (lifeScienceBusinessUnits.includes(businessUnit)) {
    return true;
  }

  const router = inject(Router);

  void router.navigate(['/']);

  return false;
}
