import { IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { ScriptLoaderService } from '@aca-new/app/shared/services/third-party-services/script-loader/script-loader.service';
import { APPCUES_ACCOUNT_ID } from '@aca-new/app/shared/tokens/appcues-account-id.token';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { QimaLoggerService, QimaOptionalType } from '@qima/ngx-qima';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppcuesService extends ScriptLoaderService {
  private readonly _userDataSentToAppcues: string[] = [
    'firstName',
    'lastName',
    'login',
    'email',
    'sic',
    'sicId',
    'companyName',
    'companyId',
    'companyType',
    'companyHierarchyType',
    'newMyQimaUser',
  ];

  private readonly _id: QimaOptionalType<string>;

  public constructor(
    private readonly _router: Router,
    private readonly _qimaLoggerService: QimaLoggerService,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(APPCUES_ACCOUNT_ID) private readonly _appcuesId: Readonly<string>
  ) {
    super();
    this._id = this._appcuesId;

    if (this._id && !isNaN(parseInt(this._id, 10))) {
      this._data = {
        src: `//fast.appcues.com/${this._id}.js`,
      };
    }
  }

  public identifyUserForAppcues(userData: Partial<IUser>): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const windowRef = this._document.defaultView as any;
    // Give only the values you need to a third party!
    const dataToSend = this._filterUserData(userData);

    windowRef.Appcues?.identify(userData.id, dataToSend);
  }

  public load$(): Observable<void> {
    if (!this._data.src) {
      return of(undefined);
    }

    return from(this._loadScript()).pipe(
      switchMap((): Observable<Event> => this._router.events),
      filter((event): boolean => event instanceof NavigationEnd),
      map((): undefined => undefined),
      catchError((err): Observable<never> => {
        this._qimaLoggerService.debug('unable to load Appcues', err);

        return throwError(err);
      })
    );
  }

  private _filterUserData(initialData: Partial<IUser>): Partial<IUser> {
    return Object.keys(initialData)
      .filter((dataKey: Readonly<string>): boolean => this._userDataSentToAppcues.includes(dataKey))
      .reduce(
        (acc: Partial<IUser>, dataKey: Readonly<string>): Partial<IUser> => ({
          ...acc,
          [dataKey]: initialData[dataKey as keyof IUser],
        }),
        {}
      );
  }
}
