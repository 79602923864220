import { ESupportedLanguage } from '@aca-new/app/shared/models/enums/supported-language.enum';

export interface ILanguage {
  iso3: string;
  title: string;
  value: string;
}

export const LANGUAGES: ILanguage[] = [
  {
    title: 'TRANSLATE.ZH',
    value: ESupportedLanguage.CHINESE,
    iso3: 'CHN',
  },
  {
    title: 'TRANSLATE.EN',
    value: ESupportedLanguage.ENGLISH,
    iso3: 'USA',
  },
  {
    title: 'TRANSLATE.ES',
    value: ESupportedLanguage.SPANISH,
    iso3: 'ESP',
  },
  {
    title: 'TRANSLATE.PT',
    value: ESupportedLanguage.PORTUGUESE,
    iso3: 'BRA',
  },
];

export const LIFE_SCIENCE_LANGUAGES: ILanguage[] = [
  {
    title: 'TRANSLATE.EN',
    value: ESupportedLanguage.ENGLISH,
    iso3: 'USA',
  },
  {
    title: 'TRANSLATE.FR',
    value: ESupportedLanguage.FRENCH,
    iso3: 'FRA',
  },
];
