import { IFile } from '@aca-new/app/pages/book/pages/confirmation/shared/components/confirmation-order-summary/shared/interfaces/file.interface';
import { NpsSurveyComponent } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/nps-survey.component';
import { ENpsSurveySource } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/enums/nps-survey-source.enum';
import { NpsSurveyService } from '@aca-new/app/pages/book/pages/confirmation/shared/components/nps-survey/shaerd/services/nps-survey.service';
import { DEFAULT_BUSINESS_SOLUTIONS } from '@aca-new/app/pages/home/shared/constants/default-business-solutions.constants';
import { IDynamicBookingMenus } from '@aca-new/app/pages/main/shared/components/aside/shared/models/interfaces/dynamic-booking-menus.interface';
import { AsideHttpService } from '@aca-new/app/pages/main/shared/components/aside/shared/services/aside.http.service';
import { ILabTestingInvoices, ILabTestingOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/lab-testing-invoice.interface';
import { IOrderFee } from '@aca-new/app/pages/payments/pages/payments-content/shared/models/interfaces/order-fee.interface';
import { OrderFeeService } from '@aca-new/app/pages/payments/pages/payments-content/shared/services/order-fee/order-fee.service';
import { IEvidenceUploadStatus } from '@aca-new/app/pages/payments/shared/models/interfaces/evidence-upload-status.interface';
import { ILtLocation } from '@aca-new/app/pages/payments/shared/models/interfaces/lt-location.interface';
import { IPaymentCategories } from '@aca-new/app/pages/payments/shared/models/interfaces/payment-response.interface';
import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { IFileResponse } from '@aca-new/app/shared/interfaces/file-response.interface';
import { IMyWindow } from '@aca-new/app/shared/interfaces/my-window.interface';
import { EBusinessSolution } from '@aca-new/app/shared/models/enums/business-solution.enum';
import { EDocType } from '@aca-new/app/shared/models/enums/doc-type.enum';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType, IHttpResponseBody } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { IInvoiceResponse } from '@aca-new/app/shared/models/interfaces/invoice-response.interface';
import { ISelect } from '@aca-new/app/shared/models/interfaces/select.interface';
import { QimaNullableType } from '@aca-new/app/shared/models/types/qima.type';
import { AppLocationService } from '@aca-new/app/shared/services/browser-services/app-location/app-location-service';
import { AppStringService } from '@aca-new/app/shared/services/exported-services/app-string/app-string.service';
import { AppFileDownloadService } from '@aca-new/app/shared/services/file-services/app-file-download.service';
import { AppFileService } from '@aca-new/app/shared/services/file-services/app-file.service';
import { HttpService } from '@aca-new/app/shared/services/http-services/http.service';
import { AppOverlayService } from '@aca-new/app/shared/services/modal-services/app-overlay/app-overlay.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { WINDOW } from '@aca-new/app/shared/tokens/window.token';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IQimaFile, QimaOptionalType } from '@qima/ngx-qima';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  public constructor(
    @Inject(WINDOW) private readonly _window: IMyWindow,
    private readonly _appLocationService: AppLocationService,
    private readonly _appStringService: AppStringService,
    private readonly _httpService: HttpService,
    private readonly _storageService: StorageService,
    private readonly _overlayService: AppOverlayService,
    private readonly _appFileDownloadService: AppFileDownloadService,
    private readonly _npsSurveyService: NpsSurveyService,
    private readonly _dialog: DialogService,
    private readonly _appFileService: AppFileService,
    private readonly _orderFeeService: OrderFeeService,
    private readonly _asideHttpService: AsideHttpService
  ) {}

  public payByPaypal(orderId: string, paymentPopup: Window | null): void {
    orderId = this._appStringService.commaToAndFunction(orderId);
    const url = `${this._appLocationService.getAcaUrl()}/paypal-payment/${orderId}`;

    if (!paymentPopup) {
      this._openPopUpWindow(url, null, null);
    } else {
      paymentPopup.location = url;
    }
  }

  public logVisitHistory$(): Observable<void> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient.post<void>(`${SERVER_URL}/parameter/v2/history?userId=${userId}`, {
      sourceId: 'PAYMENT_EMAIL_TRACKER',
      historyType: 'PAYMENT_EMAIL_TRACKER',
      historyWhen: new Date().toISOString(),
      historyWho: JSON.parse(this._storageService.getItem(EStorageKeys.USER_DATA)).company.name,
      historyAction: 'Jump to unpaid page via reminder email',
    });
  }

  public getProformaInvoiceInfo$(invoiceNumber: string): Observable<HttpResponseBodyNullableType<IFile>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const url: string = `${SERVER_URL}/user/${userId}/proforma-invoice-info?invoiceNo=${invoiceNumber}`;

    return this._httpService.httpClient
      .get<HttpResponseBodyNullableType<IFile>>(url, {
        observe: 'body',
      })
      .pipe(map((response: HttpResponseBodyNullableType<IFile>): HttpResponseBodyNullableType<IFile> => response));
  }

  public getLtLocations$(): Observable<ISelect[]> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IHttpResponseBody<ILtLocation[]>>(`${SERVER_URL}/user/${userId}/lt/offices`, {
        observe: 'body',
      })
      .pipe(
        map((response: IHttpResponseBody<ILtLocation[]>): ISelect[] => {
          const data = response.content || [];

          return [{ value: '', label: 'All Locations' }, ...data.map((val): ISelect => ({ value: val.id, label: val.name }))];
        })
      );
  }

  public getInvoiceInfo(invoiceNumber: string, rowData?: Record<string, unknown>): void {
    if (rowData?.['proformaInvoiceNumber'] && !rowData?.['invoiceNumber']) {
      this._overlayService.updateIsLoading(true);

      this.getProformaInvoiceInfo$(invoiceNumber)
        .pipe(untilDestroyed(this))
        .subscribe((res): void => {
          if (res?.content) {
            this._appFileDownloadService.downloadDirectly(res.content.fileName, res.content.id);
          }

          this._overlayService.updateIsLoading(false);
        });
    } else if (rowData?.['invoiceNumber']) {
      const userId = this._storageService.getItem(EStorageKeys.USER_ID);
      const url = `${SERVER_URL}/user/${userId}/finance/invoice/${rowData['invoiceNumber'] as string}/url`;

      this._httpService.httpClient
        .get(url)
        .pipe(untilDestroyed(this))
        .subscribe((res: QimaNullableType<IHttpResponseBody<string>>): void => {
          if (res?.message) {
            this._window.open(res.message, '_blank');
          }
        });
    }

    const serviceType = rowData?.['serviceTypeText'] || 'Invoice';
    const orderNo = rowData?.['rfqNumber'];

    // TODO: search the method and move this to  npmSurveyService
    this._npsSurveyService
      .getIsSurveyVisible$()
      .pipe(untilDestroyed(this))
      .subscribe((canOpen: boolean): void => {
        if (!canOpen) {
          return;
        }

        this._dialog.open(NpsSurveyComponent, {
          data: { orderNo, serviceType, npsFrom: ENpsSurveySource.DOWNLOAD_INVOICE },
          stopCloseWhenClickBackdrop: true,
        });
      });
  }

  public getProformaInvoiceInfo(invoiceNumber: string, rowData?: Record<string, unknown>): void {
    if (!invoiceNumber) {
      return;
    }

    this._overlayService.updateIsLoading(true);

    this.getProformaInvoiceInfo$(invoiceNumber)
      .pipe(untilDestroyed(this))
      .subscribe((res): void => {
        if (res?.content) {
          this._appFileDownloadService.downloadDirectly(res.content.fileName, res.content.id);
        }

        this._overlayService.updateIsLoading(false);
      });

    const serviceType = rowData?.['serviceTypeText'] || 'Invoice';
    const orderNo = rowData?.['rfqNumber'];

    // TODO: search the method and move this to  npmSurveyService
    this._npsSurveyService
      .getIsSurveyVisible$()
      .pipe(untilDestroyed(this))
      .subscribe((canOpen: boolean): void => {
        if (!canOpen) {
          return;
        }

        this._dialog.open(NpsSurveyComponent, {
          data: { orderNo, serviceType, npsFrom: ENpsSurveySource.DOWNLOAD_INVOICE },
          stopCloseWhenClickBackdrop: true,
        });
      });
  }

  public getProformaInvoice$(orderId: string): Observable<QimaNullableType<IInvoiceResponse>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>(`${SERVER_URL}/user/${userId}/proforma-invoice?orderId=${orderId}`, {
        observe: 'response',
      })
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>>): QimaNullableType<IInvoiceResponse> => response?.body?.content || null));
  }

  public generateDynamicProformaInvoice$(orderIds: string): Observable<QimaNullableType<IInvoiceResponse>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>(
        `${SERVER_URL}/v1.0/order/user/${userId}/proforma-invoice?orderIds=${orderIds}`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>>): QimaNullableType<IInvoiceResponse> => response?.body?.content || null));
  }

  // non-dynamic
  public generateProformaInvoice$(orderIds: string): Observable<QimaNullableType<IInvoiceResponse>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .post<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>(
        `${SERVER_URL}/user/${userId}/proforma-invoice?orderIds=${orderIds}`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IInvoiceResponse>>>>): QimaNullableType<IInvoiceResponse> => response?.body?.content || null));
  }

  public refreshGenerateProformaInvoice$(piNumbers: string): Observable<QimaNullableType<IInvoiceResponse[]>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .put<IHttpResponseBody<QimaNullableType<IInvoiceResponse[]>>>(
        `${SERVER_URL}/user/${userId}/proforma-invoice-by-pi-number?piNumbers=${encodeURIComponent(piNumbers)}`,
        {},
        {
          observe: 'response',
        }
      )
      .pipe(
        map((response: Readonly<HttpResponse<IHttpResponseBody<QimaNullableType<IInvoiceResponse[]>>>>): QimaNullableType<IInvoiceResponse[]> => response?.body?.content || null)
      );
  }

  public payByTransfer$(orderIds: string[], _orderFees: (IOrderFee | ILabTestingOrderFee)[], uploadFiles: IQimaFile[]): Observable<IHttpResponseBody<undefined>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);
    const queryString = orderIds.map((id): string => `orderIds=${encodeURIComponent(id)}`).join('&');
    const url = `${SERVER_URL}/order/${userId}/general-info/update-order-waiting-for-payment?${queryString}`;
    const uploadObservables = uploadFiles.map(
      (file): Observable<IEvidenceUploadStatus> =>
        this._appFileService.uploadFiles$(orderIds.join(','), EDocType.WAITING_FOR_PAYMENT, file).pipe(
          map((res: QimaNullableType<IFileResponse>): IEvidenceUploadStatus => ({ source: file, result: res, code: 'success' })),
          catchError((error): Observable<IEvidenceUploadStatus> => of({ source: file, result: error, code: 'error' }))
        )
    );

    return forkJoin(uploadObservables).pipe(
      switchMap((): Observable<IHttpResponseBody<undefined>> => this._httpService.httpClient.post<IHttpResponseBody<undefined>>(url, null, { observe: 'body' }))
    );
  }

  public getOrderFees$(
    orderIds: string[],
    _orderFees: (IOrderFee | ILabTestingOrderFee)[],
    orderIdToRefMap: Record<string, PageItemType>
  ): Observable<QimaOptionalType<IOrderFee[] | ILabTestingInvoices>> {
    return this._orderFeeService.getOrderFees$(orderIds).pipe(
      tap({
        next(orderFeesResult: QimaOptionalType<IOrderFee[]>): void {
          if (!orderFeesResult) {
            return;
          }

          _orderFees = orderFeesResult.map((item: IOrderFee): IOrderFee => {
            item.adyenRefNumber = item.orderId ? (orderIdToRefMap[item.orderId] as string) : null;

            return item;
          });
        },
      })
    );
  }

  /*
   * 1. Get dynamic booking menus if they were enabled for current client
   * 2. hide the menu that are not in the payment categories or its count is 0
   */
  // eslint-disable-next-line rxjs/finnish
  public getBusinessSolutions(shouldHideEmptyTable = false): Observable<Array<ISelect>> {
    return forkJoin([this._asideHttpService.getDynamicBookingMenus$(), this._getUnpaidPaymentsCategories$()]).pipe(
      map(([menus, paymentsCategories]): Array<ISelect> => {
        // 1. set the default business solutions for payments
        let businessSolutions = [
          ...DEFAULT_BUSINESS_SOLUTIONS,
          {
            label: 'DEBIT_NOTES.TITLE',
            value: EBusinessSolution.DEBIT_NOTE,
          },
        ];
        // 2. attach menus for dynamic form service types
        const keys: string[] = Object.keys(menus as Record<string, unknown>);

        businessSolutions.push(
          ...keys.map(
            (key: string): ISelect => ({
              label: menus[key][0].serviceCategory,
              value: menus[key][0].serviceCategory.toLowerCase(),
              type: menus[key][0].serviceType,
            })
          )
        );

        // 3. hide empty business solutions
        if (shouldHideEmptyTable) {
          businessSolutions = businessSolutions.filter((item: ISelect): boolean => !!paymentsCategories[item.value as keyof IPaymentCategories]);
        }

        this._storageService.setItem(EStorageKeys.DYNAMIC_BOOKING_MENUS, JSON.stringify(menus));

        return businessSolutions;
      })
    );
  }

  private _getUnpaidPaymentsCategories$(): Observable<IPaymentCategories> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this._httpService.httpClient
      .get<IHttpResponseBody<IDynamicBookingMenus>>(`${SERVER_URL}/user/${userId}/payment-categories`, {
        observe: 'body',
      })
      .pipe(map((response: IHttpResponseBody<IPaymentCategories>): IPaymentCategories => (response as IPaymentCategories) || {}));
  }

  private _openPopUpWindow(url: string | null, w: number | null, h: number | null): Window | null {
    const window = this._window;

    w = w || 800;
    h = h || 700;
    url = url || '';
    // Fixes dual-screen position Most browsers Firefox
    const dualScreenLeft = window.screenLeft;
    const dualScreenTop = window.screenTop;
    let { width } = screen;

    if (window.innerWidth) {
      width = window.innerWidth;
    } else if (document.documentElement.clientWidth) {
      width = document.documentElement.clientWidth;
    }

    let { height } = screen;

    if (window.innerHeight) {
      height = window.innerHeight;
    } else if (document.documentElement.clientHeight) {
      height = document.documentElement.clientHeight;
    }

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    return window.open(url, 'AIPayment', `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`);
  }
}
