import { IPayment } from '@aca-new/app/pages/payments/shared/models/interfaces/payment.interface';
import { AbstractDefaultPaymentService } from '@aca-new/app/pages/payments/shared/services/abstract-default-payment/abstract-default-payment.service';
import { PaymentsService } from '@aca-new/app/pages/payments/shared/services/payment/payments.service';
import { CurrencyTableCellComponent } from '@aca-new/app/shared/components/currency-table-cell/currency-table-cell.component';
import { DataTableResponseType, IDataTableData } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { DialogExportComponent } from '@aca-new/app/shared/components/modal/shared/components/dialog-export/dialog-export.component';
import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { ETableDataType } from '@aca-new/app/shared/components/table/shared/enums/table-data-type.enum';
import { ETableHeaderLayoutMode } from '@aca-new/app/shared/components/table/shared/enums/table-header-layout-mode.enum';
import { ITableBodyCell } from '@aca-new/app/shared/components/table/shared/interfaces/table-data.interface';
import { ITableHeader } from '@aca-new/app/shared/components/table/shared/interfaces/table-header.interface';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { CHECKBOX, PO_NO, PRODUCT_NAME_MULTIPLE, REF_NO, SERVICE_DATE, SERVICE_TYPE, SUPPLIER } from '@aca-new/app/shared/constants/table-header.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { IUrl } from '@aca-new/app/shared/models/interfaces/url.interface';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { AppUserSettingService } from '@aca-new/app/shared/services/user-services/app-user-setting/app-user-setting.service';
import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EQimaTableHeadCellSort } from '@qima/ngx-qima';
import { assign, cloneDeep } from 'lodash/index';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class AuditPaymentService extends AbstractDefaultPaymentService implements IPayment {
  public readonly userId = this._storageService.getItem(EStorageKeys.USER_ID);

  public paidBookingsTableHeaders: ITableHeader[] = [
    CHECKBOX,
    assign(cloneDeep(SERVICE_TYPE), {
      isFrontSort: true,
    }),
    assign(cloneDeep(SERVICE_DATE), {
      key: 'serviceDate',
      isFrontSort: true,
    }),
    assign(cloneDeep(PRODUCT_NAME_MULTIPLE), {
      isFrontSort: true,
    }),
    assign(cloneDeep(PO_NO), {
      isFrontSort: true,
    }),
    assign(cloneDeep(SUPPLIER), {
      key: 'factoryName',
      isFrontSort: true,
    }),
    assign(cloneDeep(REF_NO), {
      key: 'rfqNumber',
      isFrontSort: true,
    }),
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'proformaInvoiceNumberOrInvoiceNumber',
      label: 'TABLE.INVOICE',
      type: ETableDataType.LINK,
      isFrontSort: true,
      width: '202',
      cellClick: (cellData: Record<string, unknown>): void => {
        this._paymentsService.getInvoiceInfo(cellData.proformaInvoiceNumber as string, cellData);
      },
    },
    {
      sortType: EQimaTableHeadCellSort.ALPHABETICALLY,
      key: 'cost',
      label: 'TABLE.AMOUNT',
      isFrontSort: true,
      type: ETableDataType.CUSTOMIZED,
      mode: ETableHeaderLayoutMode.STICKY_RIGHT,
      sortFunction: (s1, s2, order): number => this.currencySortFunction(s1 as ITableBodyCell, s2 as ITableBodyCell, order),
      cellTransform(cellData: ITableBodyCell, pageItem): void {
        cellData.customizedCellConfiguration = {
          component: CurrencyTableCellComponent,
          data: {
            code: 'USD',
            value: pageItem?.cost,
          },
        };
      },
      width: '115',
    },
  ];

  public paidBookingsTableUrl: IUrl = {
    url: `${SERVER_URL}/user/${this.userId}/payments?serviceCategory=AUDIT&`,
    params: `paid=true&page=1&pagesize=10&sortType=desc&sortField=serviceDate&keyword=`,
  };

  public unpaidBookingsTableHeaders: ITableHeader[] = cloneDeep(this.paidBookingsTableHeaders);

  public unpaidBookingsTableUrl: IUrl = {
    url: `${SERVER_URL}/user/${this.userId}/payments?serviceCategory=AUDIT&`,
    params: `paid=false&page=1&pagesize=10&sortType=desc&sortField=serviceDate&keyword=`,
  };

  public constructor(
    private readonly _storageService: StorageService,
    private readonly _dialog: DialogService,
    private readonly _appUserSettingService: AppUserSettingService,
    private readonly _appAuthenticationService: AppAuthenticationService,
    _paymentsService: PaymentsService
  ) {
    super(_paymentsService);
    this._appAuthenticationService.currentUserSubject$.pipe(untilDestroyed(this)).subscribe((): void => {
      this.hasEnablePayByTransfer = 'ADVANCE_PAYMENT' === this._appUserSettingService.getOnlinePaymentType();
    });
  }

  public exportPaidBookings(): void {
    this._dialog.open(DialogExportComponent, {
      data: {
        filename: 'Audits Paid Payment List({startDate} - {endDate}).xlsx',
        title: 'PAYMENTS.EXPORT_PAYMENT_LIST',
        url: `${SERVER_URL}/user/${this.userId}/payment-export?serviceType=AUDIT&isPaid=yes&startDate={start}&endDate={end}`,
      },
    });
  }

  public exportUnpaidBookings(): void {
    this._dialog.open(DialogExportComponent, {
      data: {
        filename: 'Audits UnPaid Payment List({startDate} - {endDate}).xlsx',
        title: 'PAYMENTS.EXPORT_PAYMENT_LIST',
        url: `${SERVER_URL}/user/${this.userId}/payment-export?serviceType=AUDIT&isPaid=no&startDate={start}&endDate={end}`,
      },
    });
  }

  public transformSourceData(data: unknown, _pageSize: number): DataTableResponseType {
    return data as IDataTableData;
  }
}
