import { PageItemType } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-data.interface';
import { IDataTableProgressIndicator } from '@aca-new/app/shared/components/data-table/shared/models/interfaces/data-table-progress-indicator.interface';
import { EOrderStatus } from '@aca-new/app/shared/models/enums/order-status.enum';
import { EProgressIndicatorType } from '@aca-new/app/shared/models/enums/progress-indicator-type.enum';
import { Injectable } from '@angular/core';

enum EIndicatorType {
  DYNAMIC_SERVICE = 'DYNAMIC_SERVICE',
  INSPECTION = 'INSPECTION',
  LT = 'LT',
  WQS = 'WQS',
}

@Injectable()
export class ProgressIndicatorService {
  public progressIndicator: IDataTableProgressIndicator | undefined = undefined;

  public setProgressIndicator(cellData: Readonly<Record<string, PageItemType>>): void {
    const isLT: boolean = cellData.serviceType === 'LT';
    const type: string = this._getType(cellData);
    // inspection use isSupplierConfirmed; Audit use supplierConfirmed
    const isSupplierConfirmed: boolean = !!(cellData.isSupplierConfirmed || cellData.supplierConfirmed);
    const isAuditSkipFirstStep: boolean = !!(cellData.announcementType && cellData.announcementType === 'Un-Announced');
    const status: number = +cellData.status;
    let activeStep: number = 0;
    let tooltip: string = '';

    // Todo Deal with WQS logic
    if (isLT) {
      const ltConfig: IDataTableProgressIndicator = this.setProgressIndicatorLt(status);

      [activeStep, tooltip] = [ltConfig.activeStep, ltConfig.tooltip];
    } else if (type === EIndicatorType.INSPECTION) {
      const insConfig: IDataTableProgressIndicator = this.setProgressIndicatorIns(status, isSupplierConfirmed, isAuditSkipFirstStep);

      [activeStep, tooltip] = [insConfig.activeStep, insConfig.tooltip];
    } else if (type === 'saber' || type === 'saso-registration' || type === 'g-mark') {
      const saberConfig: IDataTableProgressIndicator = this.setProgressIndicatorSaber(status);

      [activeStep, tooltip] = [saberConfig.activeStep, saberConfig.tooltip];
    } else if (type === EIndicatorType.DYNAMIC_SERVICE) {
      const saberConfig: IDataTableProgressIndicator = this.setProgressIndicatorDynamicService(cellData);

      [activeStep, tooltip] = [saberConfig.activeStep, saberConfig.tooltip];
    } else {
      const otherConfig: IDataTableProgressIndicator = this.setProgressIndicatorOther(status, type);

      [activeStep, tooltip] = [otherConfig.activeStep, otherConfig.tooltip];
    }

    this.progressIndicator = { activeStep, tooltip };
  }

  public getProgressIndicator(): IDataTableProgressIndicator | undefined {
    return this.progressIndicator;
  }

  public setProgressIndicatorLt(status: number): IDataTableProgressIndicator {
    let activeStep: number = EProgressIndicatorType.TYPE0;
    let tooltip: string = '';

    switch (status) {
      case EOrderStatus.UNCOMPLETED:
      case EOrderStatus.WAITING_FOR_VALIDATION:
        activeStep = EProgressIndicatorType.TYPE1;

        if (status === EOrderStatus.UNCOMPLETED) {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_1_1`;
        } else {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_1_2`;
        }

        break;

      case EOrderStatus.WAITING_FOR_MORE_INFO:
      case EOrderStatus.WAITING_FOR_SAMPLE:
        activeStep = EProgressIndicatorType.TYPE2;

        if (status === EOrderStatus.WAITING_FOR_MORE_INFO) {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_2_1`;
        } else {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_2_2`;
        }

        break;

      case EOrderStatus.WAITING_FOR_ADDITIONAL_SAMPLE:
        activeStep = EProgressIndicatorType.TYPE3;
        tooltip = `TABLE.TOOLTIP.LT.STATUS_3`;
        break;
      case EOrderStatus.REPORTING:
      case EOrderStatus.WAITING_FOR_FEEDBACK:
        activeStep = EProgressIndicatorType.TYPE4;

        if (status === EOrderStatus.REPORTING) {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_4_1`;
        } else {
          tooltip = `TABLE.TOOLTIP.LT.STATUS_4_2`;
        }

        break;
    }

    return { activeStep, tooltip };
  }

  public setProgressIndicatorIns(status: number, isSupplierConfirmed: boolean, isAuditSkipFirstStep: boolean): IDataTableProgressIndicator {
    let activeStep: number = EProgressIndicatorType.TYPE0;
    let tooltip: string = '';

    if (status < EOrderStatus.WAITING_FOR_AUDIT && !isSupplierConfirmed && !isAuditSkipFirstStep) {
      activeStep = EProgressIndicatorType.TYPE1; // waiting for suppliers confirmation
    } else if (status < EOrderStatus.WAITING_FOR_AUDIT && (isSupplierConfirmed || isAuditSkipFirstStep)) {
      activeStep = EProgressIndicatorType.TYPE2; // Supplier Confirmed
    } else if (status === EOrderStatus.WAITING_FOR_AUDIT || status === EOrderStatus.ONGOING_AUDIT || status === EOrderStatus.WAITING_FOR_REPORT_SUBMISSION) {
      activeStep = EProgressIndicatorType.TYPE3; // inspector allocated
    } else if (status === EOrderStatus.WAITING_FOR_REPORT_VALIDATION || status === EOrderStatus.WAITING_FOR_REPORT_CORRECTION) {
      activeStep = EProgressIndicatorType.TYPE4; // inspection completed, waiting for report
    }

    tooltip = `TABLE.TOOLTIP.INS.STATUS_${activeStep}`;

    return { activeStep, tooltip };
  }

  public setProgressIndicatorSaber(status: number): IDataTableProgressIndicator {
    let activeStep: number = EProgressIndicatorType.TYPE0;
    let tooltip: string = '';

    switch (status) {
      case EOrderStatus.WAITING_FOR_MORE_INFO:
        activeStep = EProgressIndicatorType.TYPE1;
        break;
      case EOrderStatus.WAITING_FOR_VALIDATION:
        activeStep = EProgressIndicatorType.TYPE2;
        break;
      case EOrderStatus.WAITING_FOR_ALLOCATION:
        activeStep = EProgressIndicatorType.TYPE3;
        break;
      case EOrderStatus.WAITING_FOR_AUDIT:
      case EOrderStatus.WAITING_FOR_REPORT_VALIDATION:
        activeStep = EProgressIndicatorType.TYPE4;
        break;
    }

    tooltip = `TABLE.TOOLTIP.SABER.STATUS_${activeStep}`;

    return { activeStep, tooltip };
  }

  public setProgressIndicatorDynamicService(cellData: Readonly<Record<string, PageItemType>>): IDataTableProgressIndicator {
    const activeStep: number = +cellData.acaStatusStep;
    const tooltip: string = `${cellData.acaStatusText as string}`;

    return { activeStep, tooltip };
  }

  public setProgressIndicatorOther(status: number, type: string): IDataTableProgressIndicator {
    let activeStep: number = EProgressIndicatorType.TYPE0;
    let tooltip: string = '';

    switch (status) {
      case EOrderStatus.WAITING_FOR_VALIDATION:
      case EOrderStatus.WAITING_FOR_MORE_INFO:
        activeStep = EProgressIndicatorType.TYPE1;
        break;
      case EOrderStatus.WAITING_FOR_ALLOCATION:
      case EOrderStatus.WAITING_FOR_AUDIT:
        activeStep = EProgressIndicatorType.TYPE2;
        break;
      case EOrderStatus.WAITING_FOR_REPORT_VALIDATION:
        activeStep = EProgressIndicatorType.TYPE3;
        break;
      case EOrderStatus.WAITING_FOR_ISSUE_CERTIFICATION:
        activeStep = EProgressIndicatorType.TYPE4;
        break;
    }

    tooltip = `TABLE.TOOLTIP.${type}.STATUS_${activeStep}`;

    return { activeStep, tooltip };
  }

  private _getType(cellData: Readonly<Record<string, PageItemType>>): string {
    const isLT: boolean = cellData.serviceType === 'LT';
    const isWQS: boolean = cellData.serviceType === 'ggap';

    if (isLT) {
      return EIndicatorType.LT;
    }

    if (isWQS || cellData.auditScheme === 'GGAP') {
      return EIndicatorType.WQS;
    }

    if (cellData.type) {
      return cellData.type as string;
    }

    if (cellData.simpleServiceName) {
      return EIndicatorType.DYNAMIC_SERVICE;
    }

    return EIndicatorType.INSPECTION;
  }
}
