import { ILoginCredential, IUser } from '@aca-new/app/pages/user/shared/models/interfaces/user.interface';
import { AI_API_ACCESS_TOKEN } from '@aca-new/app/shared/constants/ai-api-access-token.constants';
import { SERVER_URL } from '@aca-new/app/shared/constants/app.constants';
import { EStorageKeys } from '@aca-new/app/shared/models/enums/storage-keys.enum';
import { HttpResponseBodyNullableType } from '@aca-new/app/shared/models/interfaces/http-response-body.interface';
import { SkipInterceptorService } from '@aca-new/app/shared/services/http-services/http.service';
import { StorageService } from '@aca-new/app/shared/services/storage-services/storage.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _skipInterceptorService: SkipInterceptorService,
    private readonly _storageService: StorageService,
    private readonly _cookieStorage: CookieService,
    private readonly _appAuthenticationService: AppAuthenticationService
  ) {}

  public getUserById$(userId: string, isRefresh?: boolean): Observable<HttpResponseBodyNullableType<IUser>> {
    let url = `${SERVER_URL}/user/v2/${userId}`;

    if (isRefresh) {
      url += '?refresh=true';
    }

    return this._httpClient
      .get<HttpResponseBodyNullableType<IUser>>(url, { observe: 'body' })
      .pipe(map((response: Readonly<HttpResponseBodyNullableType<IUser>>): HttpResponseBodyNullableType<IUser> => response));
  }

  public getToken$(data: ILoginCredential): Observable<string> {
    const headers = new HttpHeaders().set('ai-api-access-token', AI_API_ACCESS_TOKEN);

    return this._skipInterceptorService.httpClient.post<string>(`${SERVER_URL}/auth/v2/token`, data, {
      observe: 'body',
      headers,
    });
  }

  public saveAuthenticationData(params: Params): void {
    const { token, userId, sessionId } = params;

    if (token && userId && sessionId) {
      this._cookieStorage.put(EStorageKeys.AUTH_TOKEN, decodeURIComponent(token as string).trim());
      this._cookieStorage.put(EStorageKeys.SESSION_ID, decodeURIComponent(sessionId as string).trim());
      this._storageService.setItem(EStorageKeys.USER_ID, decodeURIComponent(userId as string).trim());
    }
  }

  public updateUserDataSession(userData: IUser): void {
    this._appAuthenticationService.updateUserData(userData);
    this._storageService.setItem(EStorageKeys.USER_DATA, JSON.stringify(userData));
  }

  public watchUserDataRefresh$(): Observable<HttpResponseBodyNullableType<IUser>> {
    const userId = this._storageService.getItem(EStorageKeys.USER_ID);

    return this.getUserById$(userId, true).pipe(
      tap({
        next: (userData: HttpResponseBodyNullableType<IUser>): void => {
          if (userData?.content) {
            this.updateUserDataSession(userData.content);
          }
        },
      })
    );
  }
}
