import { DialogService } from '@aca-new/app/shared/components/modal/shared/services/dialog.service';
import { IDocumentVerificationErrorHandler } from '@aca-new/app/shared/interfaces/document-verification-error-handler.interface';
import { HttpErrorHandlerService } from '@aca-new/app/shared/services/http-services/http-error-handler/http-error-handler.service';
import { AppAuthenticationService } from '@aca-new/app/shared/services/user-services/app-authentication/app-authentication.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class DocumentVerificationErrorHandlerService extends HttpErrorHandlerService implements IDocumentVerificationErrorHandler {
  public constructor(_dialog: DialogService, _appAuthenticationService: AppAuthenticationService, _router: Router) {
    super(_dialog, _appAuthenticationService, _router);
  }

  public forbidden(): void {
    void this._router.navigate(['/document-verification/document-verification-error/noAccess']);
  }

  public notFound(): void {
    void this._router.navigate(['/document-verification/document-verification-error/notFound']);
  }

  public internalServerError(): void {
    void this._router.navigate(['/document-verification/document-verification-error/notFound']);
  }
}
